<template>
  <div class="page">
    <div class="yinying" v-if="!isOn"></div>

    <div class="home">
      <div class="return main" @click="goHome"><span>返回首页</span></div>
    </div>
    <!--  -->
    <div class="bg"></div>
    <!--  -->
    <div class="inner" v-for="(item, index) in inner" :key="index">
      <div class="inner_title">
        <div>{{ item.title }}</div>
        <div class="date">
          <span
            ><i class="iconfont">&#xe64d;</i
            >{{ item.create_date | format }}</span
          >
          <span><i class="iconfont">&#xe660;</i
            >{{ item.views }}</span>
        </div>
      </div>
      <div class="m_flex_box flex_jz">
        <div v-html="item.content" class="newscontent flex-1"></div>
        <div class="right_con">
          <div class="m_flex_box flex_vc top">
            <img class="icon" src="@/assets/zxgx_tit.png" alt="">
            <h5>最新动态</h5>
          </div>
          <ul>
            <li v-for="item in list" :key="item.id" @click="toDetail(item.id)">
              <p class="time">{{ item.md }}</p>
              <p class="text line_comp2">{{ item.title }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="fixed">
      <i class="iconfont eye">&#xe8c7;</i>
      <i class="iconfont fenxiang">&#xe7fb;</i>
    </div> -->
  </div>
</template>

<script>
import qs from 'qs';
export default {
  data() {
    return {
      isOn: true,
      hoverLi: 1,
      id: "",
      inner: "",
      fullPath: '',
      list: [],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail(this.id);
  },
  filters: {
    format(value) {
      var date = new Date(value);
      var tt = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join(
        "-"
      );
      var dd = [date.getHours(), date.getMinutes(), date.getSeconds()].join(
        ":"
      );
      return tt + " " + dd;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fullPath = from ? from.fullPath : '';
    });
  },
  beforeRouteLeave(to, from, next) {
    if(to.path == '/news' && to.fullPath == this.fullPath) {
      this.$store.dispatch('saveView', ['News', 'Home']).then(() => {
        next()
      })
    } else if(to.path == '/news3' && to.fullPath == this.fullPath) {
      this.$store.dispatch('saveView', ['News3', 'Home']).then(() => {
        next()
      })
    } else {
      this.$store.dispatch('saveView', ['Home']).then(() => {
        next()
      })
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id
        }
      })
    },
    getAllNews(type, maxtype, ROWS) {
      this.$http
        .post(
          "api.do?call&code=40289fd4864fba2301864fd31083000d",
          qs.stringify({
            order: " order by create_date desc",
            PAGE: "1",
            ROWS,
            type,
            maxtype,
          })
        )
        .then((res) => {
          let newsList = res.result["40289fd4864fba2301864fd31083000d"].rows;
          this.list = newsList;
        });
    },
    getDetail(id) {
      this.$http
        .get("api.do?call&code=40289fd4864fba2301864fd64c05000e,40289fd48bada109018bb20923f70002", {
          params: { id },
        })
        .then((res) => {
          this.scrollToTop()
          this.inner = res.result["40289fd4864fba2301864fd64c05000e"];
          this.getAllNews(this.inner[0].TYPE, this.inner[0].max_type, 6);
        });
    },
    goHome() {
      this.$router.push("/");
    },
    addClass() {
      this.isOn = false;
    },
    addLi(i) {
      this.hoverLi == i;
    },
  },
};
</script>
<style scoped lang="scss">
@import "newsDetail.scss";
</style>
